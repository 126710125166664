/* Ensure the entire HTML and body take full height and remove margin */
html, body, #root {
  height: 100%;
  margin: 0;
}

/* Styles for the drawer */
.drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9); /* Dark semi-transparent background */
  color: #fff;
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 9999; /* Ensure it's above other content */
  transform: translateX(-100%); /* Initially hidden off-screen */
  opacity: 0;
  overflow: hidden;
}

.drawer.open {
  transform: translateX(0); /* Slide in */
  opacity: 1;
}

/* Ensure content within the drawer fills the height */
.drawer .h-100 {
  height: 100%;
}

/* Style for the navbar section within the drawer */
.nav-section {
  height: 100%;
  padding: 2rem;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Style for the close button */
.close-btn {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10000; /* Ensure the close button is above all content within the drawer */
}

/* Style for the contact section within the drawer */
.contact-section {
  padding: 2rem;
  background-color: #fff;
  color: black;
  height: 100%;
}

/* Text styles */
.nav-haeding {
  font-size: 36px;
  font-family: 'Degular Display', sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  padding-left: 30px; 
}

.nav-text {
  font-family: 'Degular Display', sans-serif;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 1px;
  text-align: justify;
  padding-left: 30px;
}

.text-white {
  text-align: justify;
  font-size: 28px;

}

/* Nav link styling */
.nav-link {
  text-align: -webkit-left; 
}

/* Button styling */
.btn-outline-dark {
  border-color: black;
  color: black;
}
.flex-column {
  padding-left: 50px;
}