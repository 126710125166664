/* Glance Box Styling */
.glance-box {
  background-color: #232323;
  color: #fff;
  border-radius: 33px;
  padding: 2rem 1.5rem;
  width: 350px;
  height: 301.26px;
  overflow: hidden;
  transition: color 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#glance-cont{
  max-width: 80%;
}


@media (min-width: 1920px) {
  .glance-box .heading{
    letter-spacing: 0.03em !important;
  }
}

@media (min-width: 1200px) and (max-width:1919px) {
  .glance-box{
    width: 297px;
  }

  #glance-cont{
    max-width: 85%;
  }
 }

.glance-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: left 0.4s ease-in-out;
  z-index: 0;
  border-radius: 33px;
}

.glance-box:hover::before {
  left: 0;
}

.glance-box:hover {
  color: #000;
}

.glance-box .heading {
  position: relative;
  z-index: 1;
  font-family: 'Degular Display';
  /* font-size: 1.25rem; */
  font-weight: 100;
  line-height: 1.1;
  margin: 0;
  padding: 0.03em;
  flex-grow: 1;
  text-align: left;
  letter-spacing: 0.02em;
}

.glance-box .main-heading {
  font-size: 2.2em;
  font-weight: 500;
  line-height: 0.75;
  letter-spacing: 0.01em;
}

.glance-box .line {
  display: block;
  margin-bottom: 0.5rem;
  text-align: left;
}

.glance-box img {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 172px;
  height: auto;
  transition: filter 0.3s ease;
  margin: 0;
  padding: 0;
}

.glance-box:hover img {
  filter: brightness(0);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .glance-box {
    width: 100%;
    min-height: 280px;
    padding: 1.5rem 1.25rem;
  }

  .glance-box img {
    width: 150px;
  }

  /* .glance-box .heading {
    font-size: 1.125rem;
  }

  .glance-box .main-heading {
    font-size: 2rem;
  } */
}

@media (max-width: 640px) {
  .glance-box {
    min-height: 250px;
    padding: 2.25rem 2rem;
  }

 
  .glance-box img {
    width: 180px;
    bottom: -5px;
    transform: scale(0.9);
  }

  
  /* 
  .glance-box .heading {
    font-size: 1rem;
  }

  .glance-box .main-heading {
    font-size: 1.75rem;
  } */
}

.tw-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
 
  margin: 0 auto;
  padding: 0 1rem;
}

@media (min-width: 640px) {
  .tw-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .tw-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
