/* src/components/Gallery.css */
.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  max-height: calc(100vh - 33vh);
 
  overflow-x: hidden;
}

@media (min-width: 1920px) {
  #build-button{
    width: 100% !important;
  }
  .sidebar .list-group button{
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    padding: 15px;
    max-height: calc(100vh - 180px);
  }

  #build-button{
    border-radius: 0px;
        padding: 0.5rem 4rem;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        width: auto !important;
        margin: 0 auto;
        /* text-align: center; */
        /* justify-content: center; */
        display: flex;

  }

  .sidebar {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

.gallery::-webkit-scrollbar {
  width: 6px;
}

.gallery::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 10px;
}

.gallery::-webkit-scrollbar-track {
  background-color: #171515;
}

.gallery-container {
  margin-top: 15vh;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
}

.gallery-content {
  flex: 1;
}

.gallery-item {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease, filter 0.3s ease;
  border-radius: 10px;
}

.hover-effect {
  position: relative;
  display: block;
  transition: 0.3s ease-in-out;
}

.hover-effect:hover {
  filter: brightness(0.5);
  transition: 0.4s ease-in-out;
}

.sidebar {
  background-color: #171515;
  border-radius: 20px;
  color: #fff;
  padding: 15px;
}

.highlight {
  background-color: #000000;
  color: #f8f4f4;
}

.dim {
  opacity: 1;
}

.dim .sidebar-arrow {
  visibility: hidden;
}

.list-group-item {
  background-color: #161616 !important;
  color: #fff;
  border: none;
  text-transform: capitalize !important;
  font-size: 1em;
  letter-spacing: 0.03em;
  padding: 10px 15px;
  margin-bottom: 5px;
}

.list-group {
  border-radius: 20px;
  font-size: 18px;
}

.mobile-title{
  display: none;
}

.image-text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  padding: 5px 10px;
  font-size: 21px;
  border-radius: 4px;
  display: none;
  width: 90%;
  text-align: left;
  text-transform: capitalize;
 
}

.gallery-item:hover .image-text {
  display: block;
}

/* For Webkit Browsers (Chrome, Safari, Edge) */

/* Main scrollbar track (background) */
::-webkit-scrollbar {
  width: 6px !important;
}

/* The scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #3d3d3d !important;
  border-radius: 6px !important;
}

/* On hover, change thumb color */
::-webkit-scrollbar-thumb:hover {
  background-color: #555 !important;
}

/* The scrollbar track (background area) */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* footer ----*/
.all-product-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.all-product-content {
  flex: 1;
}

.product-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: black;
  color: #fff;
  padding: 10px 0;
  z-index: 1000;
}

.mobile-filter {
  display: none;
  margin: 1rem;
  width: 50%;
}

.mobile-title {
  margin: 1rem 0;
}

.mobile-filter .dropdown-toggle {
  width: 100%;
  background-color: #171515;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.mobile-filter .dropdown-toggle::after {
  margin-left: auto;
}

.mobile-filter .dropdown-menu {
  width: 100%;
  background-color: #171515;
  border: none;
}

.mobile-filter .dropdown-item {
  color: #fff;
  background-color: #171515;
}

.mobile-filter .dropdown-item:hover {
  background-color: #000000;
}

.mobile-filter .dropdown-item.active {
  background-color: #000000;
}

@media (max-width: 768px) {
  /* .gallery {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    padding: 15px;
    max-height: calc(100vh - 180px);
  } */

  .desktop-title {
    display: none;
  }

  .sidebar {
    display: none;
  }

  .mobile-filter {
    display: block;
  }

  .mobile-title{
    display: block;
  }
}


@media screen and (min-width: 1080px) and (max-width: 1320px) {
.hover-button{
  font-size: 20px !important;
}
}
