/* General Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: clamp(15px, 2vw, 20px) clamp(20px, 4vw, 80px);
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.3)
  );
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header-left-mob{
  display: none;
}

.header-left-mob a{
  color: #fff;
}

.header-left,
.header-center,
.header-right {
  display: flex;
  align-items: center;
}

.header-left {
  flex: 1;
  justify-content: flex-start;
}

.header-center {
  flex: 1;
  justify-content: center;
}

.header-right {
  flex: 1;
  justify-content: flex-end;
}

.logo {
  height: clamp(30px, 4vw, 50px);
  width: auto;
  object-fit: contain;
}

.social-icons {
  display: flex;
  gap: clamp(10px, 1.5vw, 15px);
}

.icon {
  color: #fff;
  font-size: clamp(16px, 1.5vw, 20px);
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.icon:hover {
  opacity: 0.8;
}

.menu {
  font-size: clamp(20px, 2vw, 24px);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px;
}

.menu:hover {
  opacity: 0.8;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .header {
    padding: 10px 20px;
    justify-content: space-between;
    z-index: 999999;
  }

  
.header-left-mob{
  display: block;
}

  .header-left {
    display: none;
  }

  .header-center {
    flex: 0 0 auto;
    margin: 0;
    justify-content: flex-start;
    transform: translateX(10px);
  }

  .header-right {
    flex: 0 0 auto;
  }

  .logo {
    height: 30px;
    display: block;
  }

  .menu {
    font-size: 20px;
    margin-left: 15px;
  }
}

/* Animation for drawer open/close */
.drawer {
  transition: transform 0.3s ease-in-out;
}
