/* Common styles */
.vision-mission-container {
  width: 100%;
  max-width: 85%;
  margin: 0 auto;
  padding: 2rem 1rem;
}

@media (min-width: 1920px) {
  .vision-mission-card .description p{
    letter-spacing: 0.03em !important;
  }
}
/* Desktop Layout */
.vision-mission-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}



/* Card Styles */
.vision-mission-card {
  background: #232323;
  border-radius: 33px;
  padding: 2rem;
  color: white;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  width: 45%;
  min-height: 416px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.vision-mission-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: white;
  transition: left 0.4s ease-in-out;
  z-index: 0;
  border-radius: 33px;
}

.vision-mission-card:hover::before {
  left: 0;
}

.vision-mission-card:hover {
  color: black;
}

.vision-mission-card .card-icon {
  position: absolute;
  top: 0;
  right: 2rem;
  object-fit: cover;
  transition: filter 0.3s ease;
  z-index: 1;
}

.vision-mission-card:hover .card-icon {
  filter: brightness(0);
}

.vision-mission-card .card-content {
  position: relative;
  z-index: 1;
  margin-top: 5rem;
  width: 100%;
  text-align: left;
}

.vision-mission-card .title {
  font-family: 'Degular Display';
  font-size: 3rem;
  font-weight: 500;
  margin: 0 0 1.5rem 0;
  line-height: 1.2;
  
  text-align: left;
}

.vision-mission-card .description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.vision-mission-card .description p {
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 0;
  letter-spacing: 0.04em;
  text-align: left;
}

/* Mobile Layout (768px and below) */
@media (max-width: 768px) {
  .vision-mission-container {
    padding: 1rem 0.5rem;
  }

  .vision-mission-card .card-content{
    margin-top: 0rem !important;
  }

  .vision-mission-container{
    max-width: 100% !important;
  }

  .card-icon{
    display: none;
  }
  /* Mobile Slider */
  .mobile-slider {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    gap: 1rem;
    padding: 0.5rem 0.9rem;
    margin: 0 -0.5rem;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }

  .mobile-slider::-webkit-scrollbar {
    display: none;
  }

  .mobile-slide {
    flex: 0 0 auto;
    width: 70%; /* 1.5 items on smaller screens */
    scroll-snap-align: center;
  }

  /* Slider Dots */
  .slider-dots {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1rem;
    padding: 0.5rem 0;
  }

  .slider-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
    padding: 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .slider-dot.active {
    background-color: #fff;
  }

  /* Mobile Card Adjustments */
  .vision-mission-card {
    width: 100%;
    min-height: 350px;
    padding: 1.5rem;
    border-radius: 15px !important;
  }

  .vision-mission-card .card-icon {
    right: 1.5rem;
    width: 40%;
  }

  .vision-mission-card .title {
    font-size: 3rem;
    text-transform: uppercase;
    text-align: center;
  }

  .vision-mission-card::before{
    border-radius: 15px !important;
  }



  .vision-mission-card .description p {
    font-size: 0.875rem;
    text-align: center;
  }
}

/* Medium screens (640px to 768px) */
@media (min-width: 640px) and (max-width: 768px) {
  .mobile-slide {
    width: 50%; /* 2 items per view */
  }

  .vision-mission-container{
    max-width: 100%;
  }
}


