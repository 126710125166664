
.App {
  text-align: center;

}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Home{
  overflow: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Header Styles */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: rgba(255, 255, 255, 0.2); /* Transparent background */
  backdrop-filter: blur(10px); /* Optional: adds a blur effect to the background */
  z-index: 1000; /* Keeps header on top of other content */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
}

.header-left, .header-center, .header-right {
  flex: 1;
}

.header-center {
  text-align: center;
}

.header-right {
  display: flex;
  justify-content: flex-end;
}

.logo {
  height: 40px; /* Adjust logo size */
}

/* Ensure Menu Icon has dimensions and visibility */
.menu-icon {
  height: 30px;
  width: 30px; /* Ensure it's visible */
  cursor: pointer;
  display: inline-block; /* Ensure it's not hidden by default */
  z-index: 1000; /* Ensure it stays on top */
  position: relative;
  background-color: red; /* Temporary background color to ensure it's visible */
}

a{
  text-decoration: none !important;
}




section.banner {
  height: 100vh;  /* Full viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;  /* Remove any default margin/padding */
  padding: 0;

}



.background {
  background-image: url('../public/banner.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.banner-text {
  text-align: center;
  font-weight: bold;
  color: #fff;
  padding-top: 20%;
  font-size:50px;

}

.p-text{
  text-align: center;
  color: #fff;
  font-size:50px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
