.bg-dark {
  background-color: #000 !important;
}

/* Container Styles */
.contact-container {
  min-height: 100vh;
  padding-top: 80px;
}

.contact-row {
  min-height: calc(100vh - 80px);
  padding: 5rem;
}

.special-label {
  display: none;
}

/* Contact Text Styles */
.contactus-text p {
  font-size: clamp(36px, 6vw, 84px);
  font-family: "Degular Display";
  font-weight: 600;
  letter-spacing: 1px;
  text-align: justify;
  line-height: clamp(32px, 4vw, 55px);
  margin: 0;
}

.contactus1-text p {
  font-size:3.2rem;
  font-family: "Degular Display";
  font-weight: 400;
  letter-spacing: 1px;
  text-align: justify;
  
  margin: 0;
}

.lead-contact p {
  font-size: clamp(18px, 2.5vw, 24px);
  font-family: "Degular Display";
  font-weight: 400;
  letter-spacing: 1px;
  text-align: justify;
  margin: 0;
  line-height: clamp(20px, 2.5vw, 24px);
}

/* Form Styles */
.form-text {
  font-size: clamp(14px, 1.5vw, 18px);
  font-family: "Degular Display";
  font-weight: 400;
  letter-spacing: 1px;
  padding: 12px;
  border-radius: 0;
  background-color: rgb(45, 45, 45);
  color: #fff;
}

.form-text::placeholder {
  color: rgb(184, 179, 179);
  opacity: 1;
}

/* Button Styles */
.button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.send-button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 15px 100px;
  font-family: "Degular Display";
  font-size: clamp(14px, 1.5vw, 18px);
  cursor: pointer;
  transition: all 0.3s ease;
}

.send-button:hover {
  background-color: #f0f0f0;
}

#contact-mob{
  display: none;
}

#contact-desktop{
  display: block;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .flex-column {
    padding-left: 0px;
  }
  .contact-container {
    padding-top: 60px;
  }

  

  #contact-desktop{
    display: none;
  }

  #contact-mob{
    display: block;
  }

  .contact-row {
    min-height: calc(100vh - 60px);
    padding: 1rem 1.5rem !important;
  }

  .contactus-text p {
    font-size: 3rem !important;
    line-height: 40px !important;
    text-align: left;
  }

  .contactus1-text p {
    font-size: 3rem !important;
    line-height: 40px !important;
    text-align: left;
  }

  .lead-contact p {
    font-size: clamp(16px, 2vw, 20px);
    line-height: clamp(18px, 2vw, 22px);
    text-align: left;
  }

  .contact-left {
    margin-bottom: 2rem;
  }

  .form-text {
    font-size: clamp(12px, 1.5vw, 16px);
  }

  .send-button {
    width: 100%;
    padding: 12px 20px;
  }

  .button-wrapper {
    padding: 0;
  }
}
