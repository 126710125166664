.top-products-section {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #000;
  color: #fff;
}

.hover-button {
  margin-top: 10px;
}

/* Section Title Styling */
.products-title {
  font-family: Degular Display;
  font-size: 100px;
  
  line-height: 100px;
  text-align: center;
}

.font {
  font-family: Degular Display;
  padding-top: 10px;
}

.product-card {
  background-color: #333;
  padding: 0px 0px 5px 0px;
  border-radius: 25px;
  text-align: center;
  width: 100%;
  position: relative; /* Required for positioning the arrow icon */
  transition: background-color 0.5s ease, color 0.5s ease, box-shadow 0.5s ease;
}

.product-card:hover {
  background-color: #fff;
  color: #000;
  padding: 0px 0px 5px 0px;
  border-radius: 25px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.product-card:hover .font-span {
color: #333 !important;
}

.product-image {
  width: 100%;
  height: 100%;
  border-radius: inherit; /* Match parent border radius */
  object-fit: cover;
  transition: transform 0.1s ease;
}

/* .product-card:hover .product-image {
    transform: scale(1.01); 
    } */

.arrow-icon {
  width: 15px;
  height: 15px;
  margin-left: 10px;
  opacity: 0;
  display: none;
  transition: opacity 0.5s ease;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.product-card:hover .arrow-icon {
    opacity: 1; 
    display: block !important;
    transform: translateX(0);
}

/* Extra Large Devices (1200px and above) */


/* Extra Large Screens (1200px and above) */
@media (min-width: 1200px) and (max-width:1919px) {
  .products-title {
    font-size: 5em;
  }

  .product-card:hover .arrow-icon{
    display: block;
  }

  .product-card .arrow-icon{
    width: 20px;
    height: 20px;
    display: none;
  }

 
}

/* Large Screens (992px to 1199px) */
@media (min-width: 992px) and (max-width: 1199px) {
  .products-title {
    font-size: 80px;
  }

  .product-card {
    border-radius: 20px;
  }

  .arrow-icon {
    width: 18px;
    height: 18px;
  }
}

/* Medium Screens (768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .products-title {
    font-size: 64px;
    line-height: 1.2;
  }

  .product-card {
    border-radius: 18px;
  }

  .product-image {
    height: auto;
  }

  .arrow-icon {
    width: 15px;
    height: 15px;
  }
}

.font-span {
  font-size: 30px ;
  font-weight: 300 !important;
  color: #fff !important;
}

/* Small Screens (576px to 767px) */
@media (min-width: 576px) and (max-width: 767px) {
  .products-title {
    font-size: 48px;
    line-height: 1.3;
  }



  .product-card {
    border-radius: 15px;
  }

  .product-image {
    height: auto;
  }

  .arrow-icon {
    width: 12px;
    height: 12px;
  }

  .hover-button {
    margin-top: 20px;
  }
}

@media (min-width: 1200px) and (max-width:1919px) {
.font-span{
  font-size: 1em ;
  letter-spacing: 0.02em ;
}
}

/* Extra Small Screens (Below 576px) */
@media (max-width: 575px) {
  .products-title {
    font-size: 56px;
    line-height: 1.2;
    margin-bottom: 0px;
  }

  #product-dots{
    margin: 20px 0px !important;
  }

  #top-container{
    padding: 0px !important;
    gap: 20px !important;
  }

  .product-card {
    border-radius: 10px;
  }

  .arrow-icon {
    width: 10px;
    height: 10px;
    transform: translateX(10px) !important;
  }

  .font-span {
    font-size: 20px !important;
    font-weight: 300 !important;
  }

  #product-button{
    margin-top: 25px ;
    border-radius: 0px ;
    padding: 0.5rem 3rem;
    font-size: 18px;
    color: #000;
    font-weight: bold;
  }
}

/* Desktop View */
.desktop-view {
  display: block;
}

.mobile-view {
  display: none;
}

/* Mobile Slider Styles */
.slider-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  gap: 15px;
  padding: 0 15px;
}

.slider-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.slider-item {
  flex: 0 0 calc(66.666% - 10px); /* Show 1.5 items */
  scroll-snap-align: start;
}

/* Pagination Dots */
.pagination-dots {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
  display: none;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #666;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #fff;
}

/* Media Queries */
/* Medium Screens (768px to 991px) */
@media (max-width: 991px) {
  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block;
  }

  .slider-item {
    flex: 0 0 calc(50% - 10px); /* Show 2 items */
  }


}

/* Small Screens (576px to 767px) */
@media (max-width: 767px) {
  .slider-item {
    flex: 0 0 calc(66.666% - 10px); /* Show 1.5 items */
  }

  .pagination-dots {
 
    display: none !important;
  }

  .product-card .font{
    margin-left: -10px;
  }
  #product-button {
    font-size: 20px;
    padding: 0.6rem 3rem;
    font-weight: 900;
    width: auto !important;
    margin-top: 40px;
}
}
