/* Tailwind Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Font Face */
@font-face {
  font-family: "Degular Display";
  src: url("./assets/fonts/DegularDisplay-Medium.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Reset and Base Styles */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Root Variables */
:root {
  --font-primary: "Degular Display", sans-serif;
  --font-code: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Base Styles */
html {
  font-size: 16px;
  height: 100%;
 
  scroll-behavior: smooth;
}

body {
  height: 100%;
  font-family: var(--font-primary) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
}

/* Typography */
code {
  font-family: var(--font-code);
}

.font {
  font-family: var(--font-primary);
}

/* Responsive Media Queries */
@media screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

/* Utility Classes */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Debug Styles - Commented out by default */
/* * {
  background: rgb(0 100 0 / 0.1) !important;
  outline: 1px solid limegreen !important;
} */

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
