.main-container {
  margin-top: 100px;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

#single-sidebar .list-group-item-action:focus{
  color: #fff !important;
}

.video-thumbnail {
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
}

.video-thumbnail img {
  width: 100%;
  height: 100%;
  display: block;
}

.play-icon {
  position: absolute;
  bottom: 7%;
  left: 5%;
  padding: 10px;
  border-radius: 50%;
}

.video-thumbnail.playing {
  grid-column: span 3; /* Expand when video plays */
}

.video-thumbnail iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
}

.video-item .video-thumbnail {
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}



.video-item iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.transition-button .icon svg{
  margin-right: 15px !important;
}

#single-sidebar .dim{
  opacity: 1;
  color: #fff!important;
}

.list-group-item-action:hover{
  color: #fff;
}

#single-sidebar .list-group-item{
  color: #fff ;
}

#single-sidebar .highlighted{
  color: #fff !important;
}





.icon {
  margin-right: 5px;
}

/* .image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-height: calc(75vh);
  gap: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  margin-bottom: 15px;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
} */

.no-images-found {
  text-align: center;
  font-size: 22px;
  letter-spacing: 0.02em;
  color: gray;
  margin-top: 20px;
  width: 100%;
}

.no-images-found span {
  text-align: center;
  font-size: 22px;
  letter-spacing: 0.02em;
  color: lightgray;
  margin-top: 20px;
  margin-left: 3px ;
  font-weight: 900;
}

.grid-item {
  position: relative;
  aspect-ratio: 1 / 0.6;
  order: 2;
  /* overflow: visible; */
  transition: z-index 0.3s ease-out;
  max-height: 100vh;
  z-index: 1;
}

.list-group-item-action:focus, .list-group-item-action:hover{
  color: #fff !important;
}

.grid-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
}

/* Active state - Apply effects on click */
.grid-item.active {
    grid-column: span 3;
    width: 100%;
    height: auto;
    max-height: 65vh;
    object-fit: cover;
    order: 1;
}

.grid-item.active img {
  object-fit: cover;
  object-position: center;
}

/* Default positioning - for items that are not in the last row */
/* .grid-item.active:not(.last-row) .grid-image {
  position: absolute;
  width: 200%;
  height: 205%;
  top: 0;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
} */

/* For items in the first two columns */
.grid-item:nth-child(3n-2).active .grid-image,
.grid-item:nth-child(3n-1).active .grid-image {
  left: 0;
  transform-origin: left center;
}

/* For the last column */
.grid-item:nth-child(3n).active .grid-image {
  right: 0;
  transform-origin: right center;
}

/* Dim other images when one is active */
.image-grid .grid-item:not(.active) .grid-image {
  filter: brightness(0.9);
}

/* Handle last row scaling from bottom to top */
/* .grid-item.last-row.active .grid-image {
  position: absolute;
  width: 200%;
  height: 205%;
  object-fit: cover;
  bottom: 0;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  transform-origin: center bottom;
} */

.transition-button {
  align-items: center;
  display: flex;
  background-color: #171515;
  overflow: hidden;
  flex-direction: column;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 11px;
  height: 45px;
  justify-content: center;
  margin: 10px 0px 0px -5px;
  overflow: hidden;
  padding: 10px 20px;
  position: relative;
  transition: flex-grow 0.3s ease, color 0.3s ease, background-color 0.3s ease;
  width: 80px;
  gap: 5px;
  flex-grow: 1; /* Allow the button to grow */
}

.transition-button .icon {
  color: #fff;
  font-size: 21px;
  transition: color 0.3s ease;
}

.transition-button .text {
  color: #fff;
  display: flex;
  font-size: 12px;
  left: -100%;
  opacity: 0;
  font-family: Degular;
  position: absolute;
  transition: left 0.3s ease, opacity 0.3s ease;
  line-height: 15px;
}

.transition-button span {
  justify-content: center;
  align-items: center;
}

.transition-button:before {
  background-color: #fff;
  content: "";
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  transition: left 0.3s ease;
  width: 100%;
  z-index: 0;
}

.transition-button:hover .text {
  left: unset;
  opacity: 1; /* Show text */
}

.transition-button:hover .icon {
  color: black; /* Change icon color to black on hover */
}

.transition-button:hover::before {
  left: 0; /* Move the white background from left to right */
}

.transition-button:hover .text {
  color: #000; /* Text color becomes black on hover */
}

/* Ensure buttons are in a row */
.button-row {
  display: flex;
  gap: 10px; /* Add some space between buttons */
  justify-content: space-between;
  padding: 10px 0;
}

.button-row .col-md-4 {
  flex: 1;
  display: flex;
}

.transition-button {
  width: 100%;
  min-width: 80px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-row .transition-button {
  flex-grow: 1; /* Allow buttons to grow */
  transition: flex-grow 0.3s ease; /* Smooth transition for flex-grow */
}

.button-row .transition-button.active {
  flex-grow: 2; /* Increase size on click */
}

/* Light Mode */
.light-mode {
  background-color: #0e0e0e; /* Light background */
  color: #000000; /* Black text */
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 0px 20px;
  transition: background-color 0.4s ease, color 0.4s ease;
}

/* Dark Mode */
.dark-mode {
  background-color: #171515; /* Dark background */
  color: #ffffff; /* White text */
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 0px 20px;
  transition: background-color 0.4s ease, color 0.4s ease;
}

/* The switch - the box around the slider */
.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  border: 2px solid #383838;
  border-radius: 50px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.2em;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  left: 0.2em;
  bottom: 0.2em;
  border-radius: inherit;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.switch input:checked + .slider {
  box-shadow: 0 0 20px rgba(245, 248, 246, 0.8);
  border: 2px solid #333434;
}

.switch input:checked + .slider:before {
  transform: translateX(1.5em);
}

/* Icon inside the switch */
.switch .icon {
  font-size: 1em;
  color: #fff;
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.switch .icon.sun {
  transform: translateX(0);
  opacity: 1;
}

.switch .icon.moon {
  transform: translateX(-1.5em);
  opacity: 0;
}

.switch input:checked + .slider .icon.sun {
  transform: translateX(1.5em);
  opacity: 0;
}

.switch input:checked + .slider .icon.moon {
  transform: translateX(0);
  opacity: 1;
}

/* Time Text */
.time-text {
  font-size: 21px;
  margin: 0;
  text-align: left;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
}

/* Header Container for alignment */
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px; /* Adds padding inside */
}

.improved-design {
  font-family: "Degular Display", sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  transition: all 0.4s ease-in-out;
}

.improved-design .header-container {
  padding: 20px 10px;
}

.improved-design .back-button {
  margin-top: 0;
  margin-left: 0;
  padding: 15px 35px;
}

.improved-design .back-button::before {
  background-color: #171515;
}

.improved-design .back-button:hover {
  color: #fff;
  background-color: #343434;
}

.improved-design .back-button:hover::before {
  left: 0;
  background-color: #fff;
}

.improved-design .image-grid {
  padding: 20px;
}

.improved-design .grid-item {
  margin: 10px;
}

.improved-design .grid-image {
  border-radius: 10px;
}

.improved-design .transition-button {
  padding: 15px 25px;
}

.improved-design .transition-button .icon {
  font-size: 25px;
}

.improved-design .transition-button .text {
  font-size: 14px;
}

.improved-design .switch {
  width: 4em;
  height: 2.5em;
}

.improved-design .slider {
  border: 3px solid #414141;
}

.improved-design .switch-icon {
  font-size: 1.2em;
}

.improved-design .time-text {
  font-size: 24px;
}

/* Time Text */
.time-text {
  font-size: 21px;
  margin: 0;
  text-align: left;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
}
/* Header Container for alignment */
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px; /* Adds padding inside */
}

/* Back Button Styling */
.back-button {
  position: relative;
  display: inline-block;
  padding: 10px 30px;
  font-size: 20px;
  color: #fff;
  background-color: #343434;
  overflow: hidden;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
  font-family: "Degular Display", sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
  border: none;
  margin-top: 0px;
  margin-left: 20px;
}
.back-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: left 0.4s ease-in-out;
  z-index: 0;
  border: none;
}

.back-button:hover::before {
  left: 0;
  border: none;
}
.back-button:hover {
  color: #0e0d0d;
  background-color: #fff;
  border: none;
}

.back-button span {
  position: relative;
  z-index: 1;
  color: inherit;
}

.back-button .arrow {
  font-size: 20px;
  position: relative;
  z-index: 1;
  color: inherit;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: auto auto;
  }

  .grid-item.active{
    grid-column: span 2;
    overflow: visible;
  }

  .grid-item:nth-child(2n).active .grid-image {
    right: 0;
    left: auto;
    transform-origin: right center;
  }

  #build-button{
    border-radius: 0px ;
    padding: 0.5rem 3rem;
    font-size: 18px;
    color: #000;
    font-weight: bold;
    width: auto !important;
  }
}



/* Responsive Design */
@media screen and (max-width: 768px) {
  .image-grid {
    grid-template-columns: auto auto; /* 2 items per row on mobile */
   
    gap: 12px;
    padding-right: 10px; /* Add some spacing from the sidebar */
  }

  .col-9 {
    width: 100%;
    padding-right: 15px;
  }

  .col-md-3.sidebar-section {
    width: 100%;
    margin-top: 20px;
  }



  .button-row {
    flex-direction: column;
    gap: 15px; /* Increased gap between buttons */
    width: 100%;
    padding: 15px 0; /* Increased padding top and bottom */
  }

  .button-row .col-md-4 {
    width: 100%;
    margin: 5px 0; /* Added margin for additional spacing */
  }

  .transition-button {
    width: 100%;
    margin: 0;
    height: 55px; /* Slightly increased height */
    border-radius: 12px; /* Slightly rounded corners */
  }
}

@media screen and (max-width: 480px) {
  .image-grid {

    gap: 10px;
    margin-bottom: 10px;
  }

  .mobile-controls .Elements{
    display: flex;
    gap: 10px ;
  }



  .button-row {
    gap: 12px; /* Slightly reduced gap for very small screens */
    padding: 12px 0;
  }

  .button-row .col-md-4 {
    margin: 3px 0;
  }

  .transition-button {
    height: 50px;
  }
}

@media screen and (max-width: 768px) {
  .image-grid {
    grid-template-columns: auto auto; /* 2 columns on mobile */
    
    gap: 12px;
    margin-bottom: 12px;
  }

  .grid-item {
    aspect-ratio: 16 / 9; /* Slightly taller items on mobile */
    overflow: visible;
  }
}

@media screen and (max-width: 480px) {
  .image-grid {
    
    gap: 10px;
    margin-bottom: 10px;
  }

  .back-button{
    padding: 6px 20px;
    margin-bottom: 0px !important;
  }

  .back-button .arrow{
    font-size: 18px;
  }
}

/* Ensure the main container takes up the full height of the viewport */
.container.main-container {
  min-height: calc(95vh - 80px); /* Increased container height */
  display: flex;
  flex-direction: column;
}

/* Ensure the content takes up all available space */
.container.main-container .row {
  flex: 1;
  margin-bottom: 0;
}
