body,
html {
  margin: 0;
  padding: 0;
  background-color: #000;
  color: #fff;
}

.text-section {
  flex: 1;
  max-width: 50%;
}

.text-section h1 {
  font-size: 3em;
  line-height: 1.2em;
  margin-bottom: 20px;
}

.download-button {
  padding: 10px 20px;
  background-color: #444;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
}

.image-section img {
  max-width: 100%;
  height: auto;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: inear-gradient(135deg, #000428, #004e92);
  font-size: 20px;
}

.footer-logo img {
  height: 50px;
}

.Mob-footer{
  display: none ;
}

.Desk-footer{
  display: block;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 20px;
  /* margin: 0.5rem 0; */
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #ccc;
}

.footer-contact p {
  line-height: 10px;
  font-size: 18px;
}

.footer-contact i {
  margin-right: 10px;
}

/* Extra Large Devices (1200px and above) */
@media (min-width: 1200px) and (max-width:1919px) {
 .footer-links a{
  font-size: 16px;
  letter-spacing: 0.02em;
 }

 footer{
  margin-top: 1.5rem !important;
 }

#footer-logo{
  width: 8rem !important;
 }
 
}

@media (min-width: 992px){
footer{
  padding-bottom: 1.5rem !important;
}
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .text-section {
    max-width: 100%;
  }

  .footer {
    flex-direction: column;
    text-align: center;
    margin: 3rem 0;
  }

  .footer-links {
    text-align: center;
    margin-bottom: 1rem;
  }

  footer{
    background-color: #242424;
  }

  .Mob-Footer{
    display: block;
  }


  .Mob-Footer .font{
    display: grid;
    grid-template-columns: auto auto;
  }


.section-1{
  display: grid;
  grid-template-columns:auto auto;
  gap: 20px;
}

.section-2{
  display: grid;
  grid-template-columns: auto auto auto;
  margin-top: 1rem;
  
}

.footer-links a{
  font-size: 14px !important;
  font-weight: 300 !important;
  letter-spacing: 0.03em;
}

.Mob-footer{
  padding: 1rem 0rem !important;
}

.Mob-footer{
  display: block;
}

  .Desk-footer{
    display: none;
  }
}
